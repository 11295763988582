import { getSelectValue } from '@/utils/blocks';
import { isLinkAction, isLinkExternal } from '@/utils/links';
import { slashed, urlify } from '@/utils/url';
import type { LooseObject, Nullable, NullableOrUndefinable } from '~/types/generic';

export type ButtonTag = 'a' | 'button' | 'input' | 'pt-link-action' | 'app-link';

export interface ButtonBindings {
  outlined: boolean;
  type: Nullable<string>;
  label?: NullableOrUndefinable<string>;
  tag?: ButtonTag;
  href?: string;
  link?: string;
  action?: string;
  target?: string;
  to?: string;
  wide?: boolean;
  wider?: boolean;
  expanded?: boolean;
  description?: string;
  text_size?: string;
  display_line?: boolean;
  has_arrow?: boolean;
  has_icon?: boolean;
  has_border?: boolean;
  icon?: string;
}
export const useButtons = () => {
  const getButtonBindings = (button: ButtonBindings, fieldsPrefix = '') => {
    const width = getSelectValue(button[`${fieldsPrefix}width`]);
    const link = button[`${fieldsPrefix}link`];
    const external = isLinkExternal(link);
    const action = isLinkAction(link);

    const bindings: LooseObject = {
      outlined: !!button[`${fieldsPrefix}outlined`],
      type: getSelectValue(button[`${fieldsPrefix}type`]),
      wide: width === 'wide',
      wider: width === 'wider',
      expanded: width === 'full',
    };

    if (external) {
      bindings.tag = 'a';
      bindings.href = urlify(link);
      bindings.target = '_blank';
    } else if (action) {
      bindings.tag = 'pt-link-action';
      bindings.action = link;
      bindings.href = '#';
    } else if (link) {
      bindings.tag = 'app-link';
      bindings.to = slashed(link);
    } else {
      bindings.tag = 'a';
      bindings.href = 'javascript:;';
    }

    return bindings;
  };

  const getButtonLabel = (button: ButtonBindings, def = 'Learn more') => {
    return button && button.label ? button.label : def;
  };

  const getPrefixedButton = (prefix = 'button_', section: any): any => {
    return ['label', 'link', 'outlined', 'type', 'width'].reduce((curr, key) => {
      curr[key] = section[`${prefix}${key}`];
      return curr;
    }, {});
  };

  return {
    getButtonBindings,
    getButtonLabel,
    getPrefixedButton,
  };
};
