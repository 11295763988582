export const IMAGE_SCREENS = {
  xs: 300,
  sm: 600,
  md: 768,
  lg: 1164,
  xl: 1400,
  xxl: 1600,
  '2xl': 2000,
};

export const IMAGE_PRESET_DEFAULTS = {
  provider: 'pt',
  width: 100,
  format: 'webp',
};

export const IMAGE_PRESETS = {
  default: IMAGE_PRESET_DEFAULTS,
  hero: {
    ...IMAGE_PRESET_DEFAULTS,
    sizes: 'xs:100vw sm:100vw md:100vw lg:40vw xl:50vw',
  },
  side: {
    ...IMAGE_PRESET_DEFAULTS,
    sizes: 'xs:100vw sm:100vw md:50vw lg:50vw xl:50vw',
  },
  'side-large': {
    ...IMAGE_PRESET_DEFAULTS,
    sizes: 'xs:100vw sm:100vw md:66vw lg:66vw xl:66vw',
  },
  pricing: {
    ...IMAGE_PRESET_DEFAULTS,
    sizes: 'xs:50vw sm:40vw lg:25vw xl:20vw',
  },
  team: {
    ...IMAGE_PRESET_DEFAULTS,
    sizes: 'xs:100vw sm:100vw md:100vw lg:50vw xl:33vw',
  },
  full: {
    ...IMAGE_PRESET_DEFAULTS,
    sizes: 'xs:100vw sm:100vw md:100vw lg:100vw xl:100vw',
  },
  cover: {
    ...IMAGE_PRESET_DEFAULTS,
    sizes: 'xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw 2xl:100vw',
  },
  'floating-link': {
    ...IMAGE_PRESET_DEFAULTS,
    sizes: 'xs:100vw',
  },
};

export const IMAGE_SRCSET = [
  {
    params: { w: 300 },
    width: '300w',
  },
  {
    params: { w: 600 },
    width: '600w',
  },
  {
    params: { w: 900 },
    width: '900w',
  },
  {
    params: { w: 1200 },
    width: '1200w',
  },
  {
    params: { w: 1600 },
    width: '1600w',
  },
  {
    params: { w: 2000 },
    width: '2000w',
  },
];
