<template>
  <div
    class="image-basic"
    :class="[
      {
        'is-contain': contain,
        'is-height-contained': heightContained,
        'is-zoomable': zoomable,
      },
    ]"
  >
    <figure
      class="image-basic__fig"
      :class="{ 'is-full': !ratio }"
      :style="ratio && !heightContained ? { 'padding-top': `${ratio * 100}%` } : {}"
      :tabindex="zoomable ? 0 : -1"
    >
      <picture v-if="isGif" :style="{ '--focus': focusCss }" @click="handleImageClick">
        <img v-bind="imageAttrs" />
      </picture>
      <nuxt-picture v-else v-bind="imageAttrs" @click="handleImageClick" />
    </figure>
    <div v-if="displayCaption && caption" class="image-basic__caption">
      {{ caption }}
    </div>
    <div v-if="displayCredit && credit" class="image-basic__credit">
      {{ credit }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import isString from 'lodash/isString';
import { IMAGE_PRESETS } from '~/constants/images';
import { ZOOMABLE_IMAGE_POPUP_SLUG } from '~/constants/popups';
import { usePopupsStore } from '~/stores/popups';
import { imageUrl } from '~/utils/assets';

const props = defineProps({
  preload: {
    type: Boolean,
    default: false,
  },
  preset: {
    type: [String, Object],
    default: 'hero',
  },
  src: {
    type: String,
    default: '',
  },
  contain: {
    type: Boolean,
    default: false,
  },
  width: {
    type: [Number, String],
    default: '',
  },
  height: {
    type: [Number, String],
    default: '',
  },
  alt: {
    type: String,
    default: '',
  },
  caption: {
    type: String,
    default: '',
  },
  credit: {
    type: String,
    default: '',
  },
  ratio: {
    type: Number,
    default: null,
  },
  heightContained: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: String,
    default: 'lazy',
  },
  zoomable: {
    type: Boolean,
    default: false,
  },
  focusCss: {
    type: String,
    default: null,
  },
  displayCaption: {
    type: Boolean,
    default: false,
  },
  displayCredit: {
    type: Boolean,
    default: false,
  },
});

const popupsStore = usePopupsStore();

const isGif = computed(() => props.src.endsWith('.gif'));

const imageAttrs = computed(() => {
  if (isGif.value) {
    return {
      src: imageUrl(props.src, 'assets'),
      ...additionalAttrs.value,
    };
  }

  const preset = isString(props.preset) ? IMAGE_PRESETS[props.preset] : props.preset;

  return {
    src: props.src,
    style: `--focus: ${props.focusCss}`,
    ...preset,
    ...additionalAttrs.value,
  };
});

const additionalAttrs = computed(() => {
  const attrs: Record<string, any> = {
    alt: props.alt || '',
    preload: props.preload,
  };

  if (props.width) {
    attrs.width = props.width;
  }
  if (props.height) {
    attrs.height = props.height;
  }
  if (props.loading) {
    attrs.loading = props.loading;
  }

  return attrs;
});

const handleImageClick = () => {
  if (!props.zoomable) {
    return;
  }
  popupsStore.setZoomableImagePopupImage(imageAttrs.value);
  popupsStore.openPopup(ZOOMABLE_IMAGE_POPUP_SLUG);
};
</script>
