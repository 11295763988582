import type { ProviderGetImage } from '@nuxt/image-edge';
import { joinURL, withQuery } from 'ufo';
import { Base64 } from 'js-base64';
import { getImageRealPath } from '~/utils/assets';
import type { LooseObject } from '~/types/generic';

export const getImage: ProviderGetImage = (src, { modifiers, baseURL } = {}) => {
  const { format, width, height } = modifiers;
  const params: LooseObject = {};

  if (format) {
    params.fm = format;
  }

  if (width) {
    params.w = width;
  }
  if (height) {
    params.h = height;
  }

  const str = getImageRealPath(src, 'assets');
  const encoded = Base64.encode(str);
  let url = joinURL(baseURL, encoded);
  url = withQuery(url, params);

  return {
    url,
  };
};
