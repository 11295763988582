export const useImages = () => {
  const getUrl = (image, params = { w: 300 }) => {
    return image ? glideUrl(image, params) : '';
  };

  const getRatio = (image, defaultRatio = 9 / 16) => {
    return image && image.ratio ? 1 / image.ratio : defaultRatio;
  };

  const getPictureBindings = (image: any, additional = {}) => {
    return {
      contain: true,
      alt: getImageAlt(image),
      caption: image.caption,
      credit: image.credit,
      ratio: getRatio(image),
      src: image.id,
      focusCss: image.focus_css,
      ...additional,
    };
  };

  const getImageAlt = (image: any) => {
    return image.alt || image.title || '';
  };

  return {
    getUrl,
    getRatio,
    getPictureBindings,
    getImageAlt,
  };
};
