
import * as ptRuntime$7KbeLKyW95 from '/opt/build/repo/providers/pt.ts'

export const imageOptions = {
  "screens": {
    "xs": 300,
    "sm": 600,
    "md": 768,
    "lg": 1164,
    "xl": 1400,
    "xxl": 1600,
    "2xl": 2000
  },
  "presets": {
    "default": {
      "provider": "pt",
      "width": 100,
      "format": "webp"
    },
    "hero": {
      "provider": "pt",
      "width": 100,
      "format": "webp",
      "sizes": "xs:100vw sm:100vw md:100vw lg:40vw xl:50vw"
    },
    "side": {
      "provider": "pt",
      "width": 100,
      "format": "webp",
      "sizes": "xs:100vw sm:100vw md:50vw lg:50vw xl:50vw"
    },
    "side-large": {
      "provider": "pt",
      "width": 100,
      "format": "webp",
      "sizes": "xs:100vw sm:100vw md:66vw lg:66vw xl:66vw"
    },
    "pricing": {
      "provider": "pt",
      "width": 100,
      "format": "webp",
      "sizes": "xs:50vw sm:40vw lg:25vw xl:20vw"
    },
    "team": {
      "provider": "pt",
      "width": 100,
      "format": "webp",
      "sizes": "xs:100vw sm:100vw md:100vw lg:50vw xl:33vw"
    },
    "full": {
      "provider": "pt",
      "width": 100,
      "format": "webp",
      "sizes": "xs:100vw sm:100vw md:100vw lg:100vw xl:100vw"
    },
    "cover": {
      "provider": "pt",
      "width": 100,
      "format": "webp",
      "sizes": "xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw 2xl:100vw"
    },
    "floating-link": {
      "provider": "pt",
      "width": 100,
      "format": "webp",
      "sizes": "xs:100vw"
    }
  },
  "provider": "pt",
  "domains": [
    "api.publictrust.co.nz"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['pt']: { provider: ptRuntime$7KbeLKyW95, defaults: {"baseURL":"https://api.publictrust.co.nz/img/asset/"} }
}
        